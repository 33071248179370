import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import RecoverPass from '../views/RecoverPass.vue'
import Registrate from '../views/Registrate.vue'
import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      title: "oliCloud® - Acceso a clientes"
    }
  },
  {
    path: '/recoverPassword',
    name: 'RecoverPass',
    component: RecoverPass,
    meta: {
      title: "oliCloud® - Recuperar contraseña"
    },
  },
  {
    path: '/registrate',
    name: 'Registrate',
    component: Registrate,
    meta: {
      title: "oliCloud® - Crea tu cuenta"
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next()
})

export default router
