<template>
  <v-app>
    <v-main>
      <v-container fill-height>
        <v-layout align-center justify-center class="loginForm elevation-12">
          <v-flex md5 class="hidden-sm-and-down">
            <a target="blank" href="https://olicloud.es" class="d-flex align-center">
              <v-img
                alt="OliCloud Logo"
                contain
                src="@/assets/logo.png"
                transition="scale-transition"
                max-height="300"
              />
            </a>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs12 sm12 md6>
            <div>
              <h2>Empiece a usar oliCloud</h2>
              <v-form ref="nuevoForm">
                <v-text-field prepend-icon="mdi-account-circle" label="Nombre" type="text" v-model="create.name"></v-text-field>
                <v-text-field prepend-icon="mdi-email" label="Email" type="email" v-model="create.mail"></v-text-field>
                <v-text-field prepend-icon="mdi-phone" label="Teléfono" type="tel" v-model="create.phone"></v-text-field>
                <v-select prepend-icon="mdi-contacts" label="¿Cómo contactamos?" v-model="create.type" :items="types" item-text="text" item-value="value"></v-select>
                <v-text-field v-if="create.type == 'Telefono'" prepend-icon="mdi-clock-outline" label="¿A qué hora prefiere?" type="time" v-model="create.time"></v-text-field>
              </v-form>
              <p>{{ messageRecover }}</p>
              <v-btn @click="createNew" class="loginBtn">Aceptar</v-btn>
              <v-btn @click="goToLogin" class="loginBtn2" text>Accede a tu cuenta</v-btn>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
export default {
  name: 'Registrate',
  data: () => ({
    value: true,
    message: "",
    messageRecover: "",
    loading: false,
    types: [{value:"Telefono", text:"Prefiero por teléfono"}, {value:"Correo", text:"Prefiero por email"}],
    create: {
      name: "",
      mail: "",
      phone: "",
      type: null,
      time: ""
    },
  }),
  mounted() {
    this.cierraSesion();
  },
  methods: {
    goToLogin(){
      this.$router.push({ name: 'Login' });
    },
    async createNew(){
      const data = {
        nombre: this.create.name,
        email: this.create.mail,
        telefono: this.create.phone,
        opcion: this.create.type,
        hora: this.create.time,
      };
      const headers = {
        'Content-Type': 'application/json',
      };
      if(this.create.name!="" && this.create.mail!="" && this.create.phone!=""){
        const response = await fetch(
          'https://acceso.olicloud.es/registro.php',
          {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data),
          }
        );
        const res = await response.json();
        if(res.data == "ok"){
          this.messageRecover = "Se ha enviado un email a su correo. Por favor, revíselo.";
          this.$refs.nuevoForm.reset();
        }else{
          this.messageRecover = "ERROR. Compruebe que todos los datos son correctos. Si todo está en orden, le pedimos que se ponga en contacto con nosotros para solventar este problema. ¡Gracias!";
        }
      }else{
        this.messageRecover = "Introduzca sus datos correctamente para poder recibir toda la información";
      }
    },
    async cierraSesion(){
      if(this.$store.state.auth != ''){
        this.$store.commit('modifyAuth','');
      }
    }
  }
}
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css?family=Poppins");
main {
  background-image: url("../assets/fondoLogin.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
}
.v-application .primary-text {
  color: #91dc5a !important;
  caret-color: #91dc5a !important;
}
.loginForm{
  height: 70% !important;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding: 3%;
  opacity: 0.95;
  margin-left: 10%;
  margin-right: 10%;
}
.loginBtn{
  font-family: "Poppins" !important;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.5;
  color: #fff !important;
  text-transform: uppercase;
  height: 50px;
  width: 100%;
  border-radius: 25px;
  background: #91dc5a !important;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  transition: all 0.4s;
  margin-bottom: 40px;
}
.loginBtn2{
  font-family: "Poppins" !important;
  font-size: 15px;
  line-height: 1.5;
  text-transform: uppercase;
  height: 50px;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
}
.loginBtn2::before{
  background-color: transparent;
}
</style>
