<template>
  <v-app>
    <v-main>
      <v-container fill-height>
        <v-layout align-center justify-center class="loginForm elevation-12">
          <v-flex md5 class="hidden-sm-and-down">
            <a target="blank" href="https://olicloud.es" class="d-flex align-center">
              <v-img
                alt="OliCloud Logo"
                contain
                src="@/assets/logo.png"
                transition="scale-transition"
                max-height="300"
              />
            </a>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs12 sm12 md6>
            <div v-if="whatShow == 'login'">
              <h2 style="margin-bottom: 7%;">Inicio de sesión</h2>
              <v-form>
                <v-text-field v-on:keyup.enter="login" id="user" prepend-inner-icon="mdi-account-circle" name="login" label="Usuario" type="text" v-model="post.user"></v-text-field>
                <v-text-field v-on:keyup.enter="login" id="password" prepend-inner-icon="mdi-lock" name="password" label="Contraseña" :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'" @click:append="() => (value = !value)" :type="value ? 'password' : 'text'" v-model="post.pass"></v-text-field>
              </v-form>
              <p>{{ message }}</p>
              <v-btn @click="login" :loading="loading" class="loginBtn">Acceder</v-btn>
              <v-btn @click="show('recupera')" class="loginBtn2" text>¿Olvidaste tu contraseña?</v-btn>
              <v-btn @click="goToForm" class="loginBtn2" text>Crea tu cuenta</v-btn>
            </div>
            <div v-if="whatShow == 'recupera'">
              <h2>Recupera contraseña</h2>
              <v-form>
                <v-text-field v-on:keyup.enter="recover" id="recoverUser" prepend-icon="mdi-account-circle" name="recoverUser" label="Usuario" type="text" v-model="putRecover.user"></v-text-field>
              </v-form>
              <p>{{ messageRecover }}</p>
              <v-btn @click="recover" class="loginBtn">Recuperar</v-btn>
              <v-btn @click="show('login')" class="loginBtn2" text>Inicia sesión</v-btn>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import {BASE_URL, URL_ALMAZARA, URL_PRODUCTOR} from '../components/constants.js'
export default {
  name: 'Login',
  data: () => ({
    value: true,
    message: "",
    messageRecover: "",
    whatShow: "login",
    loading: false,
    types: [{value:"Telefono", text:"Prefiero por teléfono"}, {value:"Correo", text:"Prefiero por email"}],
    post: {
      user: "",
      pass: ""
    },
    putRecover: {
      user: ""
    },
  }),
  mounted() {
    this.cierraSesion();
  },
  methods: {
    goToForm(){
      this.$router.push({ name: 'Registrate' });
    },
    async login() {
      this.loading = true;
      if(this.post.user == "admin@inforcloud.es"){
        try {
          await fetch('https://'+this.post.pass+'.olicloud.es', { mode: "no-cors" });
          var f = document.createElement('form');
          f.action='https://'+this.post.pass+'.olicloud.es/inicio.php';
          f.method='POST';

          var usuario=document.createElement('input');
          usuario.type='hidden';
          usuario.name='usuario';
          usuario.value='admin@inforcloud.es';
          f.appendChild(usuario);
          var token=document.createElement('input');
          token.type='hidden';
          token.name='token';
          token.value='UsuarioMaestro';
          f.appendChild(token);
          var tipo=document.createElement('input');
          tipo.type='hidden';
          tipo.name='tipo';
          tipo.value='Administrador';
          f.appendChild(tipo);
          var almazara=document.createElement('input');
          almazara.type='hidden';
          almazara.name='almazara';
          almazara.value="";
          f.appendChild(almazara);

          document.body.appendChild(f);
          f.submit();
        } catch (error) {
          this.message = "ERROR. La licencia no existe";
          this.loading = false;
        }
      }else{
        var postData = {
          email: this.post.user,
          password: this.post.pass,
        };
        const headers = {
          'Content-Type': 'application/json',
        };
        const response = await fetch(
          `${BASE_URL}/auth/login`,
          {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(postData),
          }
        );
        switch (response.status) {
          case 200: 
            const json = await response.json();
            this.message = "";
            if(json.body.tipo == "Almazara"){
              this.$store.commit('modifyAuth',json.body.token);
              location.href = `${URL_ALMAZARA}/#tk${json.body.token}`;
            }else{
              if(json.body.tipo == "Productor"){
                this.$store.commit('modifyAuth',json.body.token);
                location.href = `${URL_PRODUCTOR}/#tk${json.body.token}`;
              }else{
                try {
                  await fetch('https://'+json.body.licencia+'.olicloud.es', { mode: "no-cors" });
                  var f = document.createElement('form');
                  if(json.body.tipo == "Cliente"){
                    f.action='https://'+json.body.licencia+'.olicloud.es/cliente/index.php';
                  }else{
                    f.action='https://'+json.body.licencia+'.olicloud.es/inicio.php';
                  }
                  f.method='POST';

                  var usuario=document.createElement('input');
                  usuario.type='hidden';
                  usuario.name='usuario';
                  usuario.value=this.post.user;
                  f.appendChild(usuario);
                  var token=document.createElement('input');
                  token.type='hidden';
                  token.name='token';
                  token.value=json.body.token;
                  f.appendChild(token);
                  var tipo=document.createElement('input');
                  tipo.type='hidden';
                  tipo.name='tipo';
                  tipo.value=json.body.tipo;
                  f.appendChild(tipo);
                  var almazara=document.createElement('input');
                  almazara.type='hidden';
                  almazara.name='almazara';
                  almazara.value=json.body.almazara;
                  f.appendChild(almazara);

                  document.body.appendChild(f);
                  f.submit();
                } catch (error) {
                  this.message = "Todavía no está disponible su acceso a oliCloud. Es posible que deba esperar hasta mañana para acceder.";
                  this.loading = false;
                }
              }
            }
            break;
          case 400:
            if(this.post.user!="" && this.post.pass!=""){
              if(this.post.user == "admin" && this.post.pass.indexOf("1SCRXXXING-") > -1){
                let [clave, licencia] = this.post.pass.split("-");
                if(clave == "1SCRXXXING"){
                  var f = document.createElement('form');
                  f.action='https://'+licencia+'.olicloud.es/inicio.php';
                  f.method='POST';
                  var usuario=document.createElement('input');
                  usuario.type='hidden';
                  usuario.name='usuario';
                  usuario.value="admin@inforcloud.es";
                  f.appendChild(usuario);
                  var token=document.createElement('input');
                  token.type='hidden';
                  token.name='token';
                  token.value="InforCloud";
                  f.appendChild(token);
                  var tipo=document.createElement('input');
                  tipo.type='hidden';
                  tipo.name='tipo';
                  tipo.value="Administrador";
                  f.appendChild(tipo);

                  document.body.appendChild(f);
                  f.submit();
                }
              }else{
                this.message = "Los campos de inicio de sesión no son correctos";
                this.loading = false;
              }
            }else{
              this.message = "Los campos de inicio de sesión son obligatorios";
              this.loading = false;
            }
            break;
          case 401:
            this.message = "Inicio de sesión incorrecto";
            this.loading = false;
            break;
          case 500:
            this.message = "Error en el servidor. Consulte a soporte técnico";
            this.loading = false;
            break;
        }
      }
    },
    show(what){
      this.whatShow = what;
    },
    async recover(){
      var putData = {
        email: this.putRecover.user
      };
      const headers = {
        'Content-Type': 'application/json',
      };

      const response = await fetch(
        `${BASE_URL}/auth/recoverPass`,
        {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify(putData),
        }
      );
      switch (response.status) {
        case 202:
          this.messageRecover = "Se ha enviado un email a su correo. Por favor, revíselo.";
          break;
        case 400:
          if(this.putRecover.user!="")
            this.messageRecover = "Error en la aplicación. Consulte a soporte técnico.";
          else
            this.messageRecover = "Introduzca su email para recuperar la contraseña";
          break;
        case 500:
          this.messageRecover = "Error en el servidor. Consulte a soporte técnico.";
          break;
      }
    },
    async checkJWT(){
      var postData = {
        email: this.post.user,
        password: this.post.pass,
      };
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+this.$store.state.auth
      };

      const response = await fetch(
        `${BASE_URL}/auth/checkJWT`,
        {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(postData),
        }
      );
      switch (response.status) {
        case 200:
          return "ok";
        default:
          return response.status;
        }
    },
    async cierraSesion(){
      if(this.$store.state.auth != ''){
        this.$store.commit('modifyAuth','');
      }
    }
  }
}
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css?family=Poppins");
main {
  background-image: url("../assets/fondoLogin.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
}
.v-application .primary-text {
  color: #91dc5a !important;
  caret-color: #91dc5a !important;
}
.loginForm{
  height: 70% !important;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding: 3%;
  opacity: 0.95;
  margin-left: 10%;
  margin-right: 10%;
}
.loginBtn{
  font-family: "Poppins" !important;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.5;
  color: #fff !important;
  text-transform: uppercase;
  height: 50px;
  width: 100%;
  border-radius: 25px;
  background: #91dc5a !important;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  transition: all 0.4s;
  margin-bottom: 40px;
}
.loginBtn2{
  font-family: "Poppins" !important;
  font-size: 15px;
  line-height: 1.5;
  text-transform: uppercase;
  height: 50px;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
}
.loginBtn2::before{
  background-color: transparent;
}
</style>
