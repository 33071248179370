<template>
<v-app>
    <v-main>
      <v-container fill-height>
        <v-layout align-center justify-center class="loginForm elevation-12">
          <v-flex md5 class="hidden-sm-and-down">
            <a target="blank" href="https://olicloud.es" class="d-flex align-center">
              <v-img
                alt="OliCloud Logo"
                contain
                src="@/assets/logo.png"
                transition="scale-transition"
                max-height="300"
              />
            </a>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs12 sm12 md6>
            <v-form>
              <v-text-field v-on:keyup.enter="recoverPassword" id="password1" prepend-icon="mdi-lock" name="password1" label="Nueva contraseña" :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'" @click:append="() => (value = !value)" :type="value ? 'password' : 'text'" v-model="putData.pass1"></v-text-field>
              <v-text-field v-on:keyup.enter="recoverPassword" id="password2" prepend-icon="mdi-lock" name="password2" label="Confirmar contraseña" :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'" @click:append="() => (value = !value)" :type="value ? 'password' : 'text'" v-model="putData.pass2"></v-text-field>
            </v-form>
            <p>{{ message }}</p>
            <v-btn @click="recoverPassword" :disabled="disabled == true" class="loginBtn">Aceptar</v-btn>
            <v-btn @click="goLogin" text class="loginBtn2">Inicia sesión</v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import {BASE_URL} from '../components/constants.js'
export default {
  name: 'RecoverPass',
  data: () => ({
    value: true,
    message: '',
    disabled: 'false',
    putData: {
      pass1: '',
      pass2: ''
    }
  }),
  methods: {
    async recoverPassword() {
      const token = this.$route.query.token;
      const email = this.$route.query.email;
      if (!token || !email) {
        this.message = "El enlace no es válido";
        return;
      }

      const pass = this.putData.pass1;
      const repeatPass = this.putData.pass2;
      if (!pass) {
        this.message = "Introduzca una contraseña válida";
        return;
      }
      if (pass != repeatPass) {
        this.message = "Las contraseñas no coinciden";
        return;
      }

      // Everything ok
      this.message = "";
      const unexpectedError = "Ha ocurrido un error inesperado, si el problema persiste contacte con el administrador";

      // Send request
      var putData = {
        email: email,
        token: token,
        contrasena: pass
      };
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+this.$store.state.auth
      };

      const response = await fetch(
        `${BASE_URL}/auth/editPass`,
        {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify(putData),
        }
      );
      switch (response.status) {
        case 200:
          this.disabled = true;
          this.message = "Su contraseña ha sido modificada con éxito. Puede volver a la página de login.";
          break;
        default:
          this.disabled = true;
          this.message = unexpectedError;
          break;
      }
    },
    goLogin(){
      this.$router.push({name: 'Login'});
    }
  }
}
</script>
<style scoped>
main {
  background-image: url("../assets/fondoLogin.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
}
.v-application .primary-text {
  color: #91dc5a !important;
  caret-color: #91dc5a !important;
}
.loginForm{
  height: 70% !important;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding: 3%;
  opacity: 0.95;
  margin-left: 10%;
  margin-right: 10%;
}
.loginBtn{
  font-family: "Poppins" !important;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.5;
  color: #fff !important;
  text-transform: uppercase;
  height: 50px;
  width: 100%;
  border-radius: 25px;
  background: #91dc5a !important;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  transition: all 0.4s;
  margin-bottom: 40px;
}
.loginBtn2{
  font-family: "Poppins" !important;
  font-size: 15px;
  line-height: 1.5;
  text-transform: uppercase;
  height: 50px;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
}
.loginBtn2::before{
  background-color: transparent;
}
</style>