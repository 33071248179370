import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const vuetify = new Vuetify({
    theme: {
        themes: {
        light: {
            primary: "#91dc5a",
            secondary: "#333333",
        }
        },
    },
})

export default vuetify
